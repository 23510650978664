import { t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import { Content } from "../Content";
import { Intro } from "../Intro";

const Question = (props: { title: string; description: string }) => {
  return (
    <x.div mb4 p900>
      <x.div f20 bold p900 header>
        {props.title}
      </x.div>
      <x.div f16 p900 opacity80 mt1>
        {props.description}
      </x.div>
    </x.div>
  );
};

export const QuestionsSection = () => {
  return (
    <Content bgp50 pb3="max-md" pb120p="min-md" id="questions">
      <x.div>
        <Intro
          title={t("Frequently asked questions")}
          sub={t(
            "Still have a question? We've compiled a list of the most common questions below."
          )}
        />

        <x.div gridrow gap4="min-md">
          <x.div col12="max-md" col6="min-md">
            <Question
              title={t("Which devices are supported?")}
              description={t(
                "Maintmark™ is available on all modern devices. We provide native applications for iPhone, iPad and Android. On desktop we provide a web application that runs on all modern browsers."
              )}
            />
            <Question
              title={t("What if we have more than 5 users?")}
              description={t(
                "No problem! Get in contact with us and let us know your needs and we promise to give you an offer with a competitive price."
              )}
            />
            <Question
              title={t("Do you support integrations?")}
              description={t(
                "Of course. We understand that seamless workflows is a critical piece to enable an efficient workplace. For that reason, we built maintmark from the ground up with integrations in mind."
              )}
            />
          </x.div>
          <x.div col12="max-md" col6="min-md">
            <Question
              title={t(
                "How is maintmark different from other work order systems?"
              )}
              description={t(
                "The idea for maintmark™ came out of the realisation that the market is full of software that do not focus on the user. We decided to end that madness and create solutions that are truly user friendly."
              )}
            />
            <Question
              title={t("Do you provide training?")}
              description={t(
                "Yes we do. However, it's our deep belief that tools shouldn't need any training or manual. They should be easy to understand and easy to pick up for anyone with basic understanding of smart phones and computers."
              )}
            />
            <Question
              title={t(
                "What if we want to transfer data from our existing system?"
              )}
              description={t(
                "Don't worry! We have extensive experience from previous migrations of all sorts. Let us know what you are currently using and we'll provide you with guidance on how to migrate to maintmark™."
              )}
            />
          </x.div>
        </x.div>
      </x.div>
    </Content>
  );
};
