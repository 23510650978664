import { t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";
import { Feature } from "../components/Feature";
import { Content } from "../Content";
import { Intro } from "../Intro";
import PhoneImg from "./images/iphone@3x.png";

export const MobileSection = styled((props: x.divProps) => {
  return (
    <Content pb120p bgp50 id="features" {...props}>
      <Intro title={t("Work orders at your fingertips")} />
      <x.div row="min-md" centers>
        <x.div flex>
          <Feature
            textright="min-md"
            center="max-md"
            title={t("Intelligent shortcuts")}
            description={t(
              "Maintmark™ is designed to make work easier by understanding the context you are in and presenting shortcuts to help you complete your actions in seconds."
            )}
          />
          <Feature
            textright="min-md"
            center="max-md"
            mt4
            title={t("Custom fields")}
            description={t(
              "We understand that business data is not uniform. Sometimes you need an extra field of custom information. Maintmark got you covered with custom fields."
            )}
          />
        </x.div>
        <x.div className="image" row centerp centers hide="max-md">
          <x.img src={PhoneImg} width={220} height={440} />
        </x.div>
        <x.div flex>
          <Feature
            mt4="max-md"
            center="max-md"
            title={t("Find things fast")}
            description={t(
              "Whether you're looking for a specific title, description, assignee, asset or anything else, our powerful search tool will get you the results you need."
            )}
          />
          <Feature
            center="max-md"
            mt4
            title={t("And much more...")}
            description={t(
              "Book a demo to explore all the features of maintmark™."
            )}
          />
        </x.div>
      </x.div>
    </Content>
  );
})`
  .image {
    width: 350px;
  }
`;
