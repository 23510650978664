import { DateTime } from "../types";
import { createEvent } from "../events";

export interface Notice {
  id: string;
  title: string;
  text: string;
  createdAt: DateTime;
  createdBy: string;
  undo?: () => void;
}

export const noticeEvent = createEvent<Notice>("notice");
