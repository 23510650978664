import { t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import { theme } from "@maintmark/shared/src/ui";
import styled from "styled-components";
import { Content } from "./Content";

const Button = styled((props: React.PropsWithChildren<x.aProps>) => {
  return (
    <x.a
      bgp900
      bgblack="hover"
      noselect
      br24p
      ph2
      row
      centers
      white
      semibold
      f16
      h3
      pointer
      nowrap
      {...props}
    />
  );
})`
  :hover,
  :focus,
  :active {
    color: ${theme.colors.common.white};
  }
`;

const Link = (props: React.PropsWithChildren<x.aProps>) => {
  return (
    <x.a
      f16
      pointer
      opacity80
      opacity100="hover"
      p900
      noselect
      scale105="hover"
      transition300
      {...props}
    />
  );
};

const Logo = (props: x.divProps) => {
  return (
    <x.div medium f24 p900 row header pointer {...props}>
      <x.div bold>maint</x.div>
      mark
      <x.div f14 mt3p ml2p>
        ™
      </x.div>
    </x.div>
  );
};

export const NavBar = () => {
  return (
    <Content>
      <x.div h100p column centerp maxw1200p>
        <x.div row centerp hide="min-md">
          <x.div flex />
          <Logo mb1 f20 />
          <x.div flex />
        </x.div>
        <x.div row centers>
          <Logo hide="max-md" />
          <x.div flex />
          <x.div row>
            <Link mr2="max-md" mr3="min-md" href="/#features">
              {t("Features")}
            </Link>
            <Link mr2="max-md" mr3="min-md" href="/#pricing">
              {t("Pricing")}
            </Link>
            <Link mr1="max-md" mr3="min-md" href="/#contact">
              {t("Contact")}
            </Link>
          </x.div>
          <x.div hide="max-md">
            <Button href="https://calendly.com/maintmark/svenska-demo-av-losning-30-min">
              {t("Book a demo")}
            </Button>
          </x.div>
          <x.div flex hide="min-md" />
        </x.div>
      </x.div>
    </Content>
  );
};
