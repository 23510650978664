import _ from "lodash";
import React from "react";
import { time } from "../../time";
import { DateTime } from "../../types";

interface Props<T> {
  date: DateTime;
  data: T;
  WeekComponent: React.ComponentType<
    React.PropsWithChildren<{
      startOfMonth: DateTime;
      startOfNextMonth: DateTime;
      startOfWeek: DateTime;
      data: T;
      index: number;
    }>
  >;
}

export const Month = <T,>(props: Props<T>) => {
  const { WeekComponent, date, data } = props;
  const startOfMonth = time.startOfMonth(date);
  const startOfNextMonth = time.addMonths(startOfMonth, 1);
  const numWeeks = time.getWeeksInMonth(date);
  const firstWeekStart = time.startOfWeek(startOfMonth);

  return (
    <>
      {_.range(0, numWeeks).map((index) => {
        const startOfWeek = time.addWeeks(firstWeekStart, index);

        return (
          <WeekComponent
            key={startOfWeek}
            startOfWeek={startOfWeek}
            startOfMonth={startOfMonth}
            startOfNextMonth={startOfNextMonth}
            data={data}
            index={index}
          />
        );
      })}
    </>
  );
};
