// import { ui } from "@maintmark/shared";
import { ui } from "@maintmark/shared-web";
import { GlobalStyle } from "./styles/GlobalStyle";
import { theme } from "@maintmark/shared/src/ui";
import { x } from "@maintmark/shared-web/src/ui";
import { NavBar } from "./NavBar";
import { VideosSection } from "./sections/VideosSection";
import { TestimonialsSection } from "./sections/TestimonialsSection";
import { PricingSection } from "./sections/PricingSection";
import { ContactSection } from "./sections/ContactSection";
import { HeadSection } from "./sections/HeadSection";
import { Footer } from "./Footer";
import styled from "styled-components";
import { QuestionsSection } from "./sections/QuestionsSection";
import { DesktopSection } from "./sections/DesktopSection";
import { MobileSection } from "./sections/MobileSection";
import { IndustriesSection } from "./sections/IndustriesSection";

const Video = styled((props: x.divProps) => {
  return (
    <x.div {...props}>
      <x.div relative className="video-container">
        <video autoPlay muted loop>
          <source src="/videos/video.mov" type="video/mp4" />
        </video>
        <x.div fill bgwhite opacity70 />
      </x.div>
    </x.div>
  );
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  .video-container {
    position: relative;
    display: grid;
    place-items: center;
    height: 834px;
    width: 100vw;
    margin: 0 auto;
    background: #ccc;

    video {
      position: absolute;
      z-index: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &::-webkit-media-controls {
        display: none !important;
      }
    }
  }
`;

export const App = () => {
  return (
    <ui.StyleProvider>
      <GlobalStyle colors={theme.colors} constants={theme.constants} />
      <ui.x.div relative>
        <Video />
        <NavBar />
        <HeadSection />
        {/* <VideosSection /> */}
        <DesktopSection />
        <MobileSection />
        <IndustriesSection />
        {/* <TestimonialsSection /> */}
        <PricingSection />
        <ContactSection />
        <QuestionsSection />
        <Footer />
      </ui.x.div>
    </ui.StyleProvider>
  );
};
