import { t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import React from "react";
import styled from "styled-components";

export const Intro = styled(
  (
    props: React.PropsWithChildren<{
      title: string;
      sub?: string;
    }>
  ) => {
    const { title, sub, ...other } = props;
    return (
      <x.div {...other} column centers>
        <x.div f36 light header center p900 mb20p={!!sub}>
          {title}
        </x.div>
        {sub && (
          <x.div f20 extralight header center p900 className="sub">
            {sub}
          </x.div>
        )}
      </x.div>
    );
  }
)`
  padding-top: 120px;
  padding-bottom: 120px;
  .sub {
    max-width: 60%;
  }
`;
