import { t } from "@maintmark/shared";
import { x, TextArea } from "@maintmark/shared-web/src/ui";
import { Content } from "../Content";
import { Intro } from "../Intro";
import GpsImg from "./images/gps@3x.png";
import PhoneImg from "./images/phone@3x.png";
import AtSignImg from "./images/atsign@3x.png";
import styled from "styled-components";
import React from "react";
import { theme } from "@maintmark/shared/src/ui";

const LinkButton = styled((props: React.PropsWithChildren<x.aProps>) => {
  return (
    <x.a
      bgp900
      bgblack="hover"
      noselect
      br24p
      ph2
      row
      centers
      white
      semibold
      f16
      h3
      pointer
      nowrap
      {...props}
    />
  );
})`
  :hover,
  :focus,
  :active {
    color: ${theme.colors.common.white};
  }
`;

const Button = (props: React.PropsWithChildren<x.divProps>) => {
  return (
    <x.div
      bgp900
      bgblack="hover"
      noselect
      br24p
      ph2
      row
      centers
      white
      semibold
      f16
      h3
      pointer
      nowrap
      {...props}
    />
  );
};

export const ContactForm = (props: x.divProps) => {
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const valid = /*email.indexOf("@") > 0 &&*/ message.trim().length > 0;

  const sendMessage = React.useCallback(() => {
    if (!valid) {
      return;
    }
  }, [valid]);

  const link = `mailto:info@maintmark.com?subject=Contact&body=${encodeURIComponent(
    message
  )}`;

  return (
    <x.div
      col12="max-md"
      col6="min-md"
      column
      h100p="max-md"
      className="contact"
      mb4="max-md"
      {...props}
    >
      {/* <x.div row>
        <TextInput
          flex
          value={email}
          onUpdate={setEmail}
          placeholder={t("E-mail")}
          mb2
          type="email"
        />
        <TextInput
          ml2
          flex
          value={phone}
          onUpdate={setPhone}
          placeholder={t("Phone number...")}
          type="tel"
        />
      </x.div> */}

      <TextArea
        onUpdate={setMessage}
        value={message}
        placeholder={t("Message...")}
        flex
      />

      <x.div row mt2 centers>
        <x.div flex>
          <x.div f14 opacity80 p900 className="note">
            {t("We will respond to the provided email within 24 hours.")}
          </x.div>
        </x.div>
        <LinkButton
          opacity50={!valid}
          noevents={!valid}
          onClick={sendMessage}
          href={link}
        >
          {t("Send message")}
        </LinkButton>
      </x.div>
    </x.div>
  );
};

export const ContactSection = styled((props: x.divProps) => {
  return (
    <Content pb120p="min-md" className={props.className} id="contact">
      <Intro
        title={t("Get in contact with us")}
        sub={t(
          "We're here to answer any question you might have. Send us a message and we'll reply within 24 hours."
        )}
      />

      <x.div gridrow gap3="min-md" mb4="max-md">
        <ContactForm hide="min-md" />
        <x.div col12="max-md" col6="min-md" relative className="contact">
          <x.div fill bgp900 opacity5 br12p noevents />
          <x.div p2>
            <x.div header f24 bold p900>
              {t("Contact information")}
            </x.div>
            <x.div mt1 f16 opacity70 p900>
              {t(
                "Feel free to contact us using any of the contact details below. Alternatively, send us a message using the form."
              )}
            </x.div>
            <x.div mt2 f16 p900>
              <x.a row centers href="tel:+46706710448">
                <x.div w2 row centerp mr5p>
                  <x.img src={PhoneImg} width={19} height={19} />
                </x.div>
                {t("+46 (0) 70 671 04 48")}
              </x.a>
              <x.a row centers mt20p href="mailto:info@maintmark.com" pointer>
                <x.div w2 row centerp mr5p>
                  <x.img src={AtSignImg} width={18} height={19} />
                </x.div>
                {t("info@maintmark.com")}
              </x.a>
              <x.div row centers mt20p>
                <x.div w2 row centerp mr5p>
                  <x.img src={GpsImg} width={16} height={20} />
                </x.div>
                {t("MAINTMARK AB")}
              </x.div>
              <x.div pl2 ml5p>
                <x.div>{t("Linköping Science Park")}</x.div>
                <x.div>{t("Teknikringen 10")}</x.div>
                <x.div>{t("583 30  Linköping")}</x.div>
                <x.div>{t("SWEDEN")}</x.div>
              </x.div>
            </x.div>
          </x.div>
        </x.div>
        <ContactForm hide="max-md" />
      </x.div>
    </Content>
  );
})`
  .contact {
    height: 400px;
    .note {
      max-width: 240px;
    }
  }
`;
