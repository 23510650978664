import { t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";
import { Content } from "../Content";
import { Intro } from "../Intro";
import AssetImg from "./images/asset-industry@3x.png";
import ProductImg from "./images/product-industries@3x.png";
import ServiceImg from "./images/service-industries@3x.png";
import CheckImg from "./images/check@3x.png";

const Area = (props: { text: string }) => {
  return (
    <x.div row centers mb10p>
      <x.img src={CheckImg} width={18} height={17} />
      <x.div f16 p900 medium ml8p header>
        {props.text}
      </x.div>
    </x.div>
  );
};

const Industry = styled(
  (props: x.divProps & { image: string; title: string; areas: string[] }) => {
    const { onClick, title, areas, ...other } = props;

    return (
      <x.div bgwhite br12p shadowPopup mask mb4="max-md" {...other}>
        <x.div
          className="image-container"
          onClick={onClick}
          relative
          pointer
          mask
        >
          <x.div bgp900 fill noevents />
          <x.div className="image" onClick={onClick} fill />
        </x.div>
        <x.div className="text">
          <x.div pv2 ph2>
            <x.div f24 bold p900 header>
              {title}
            </x.div>
            <x.div f16 p900 mt1 opacity80>
              {areas.map((area, index) => (
                <Area key={index} text={area} />
              ))}
            </x.div>
          </x.div>
        </x.div>
      </x.div>
    );
  }
)`
  & .image-container {
    height: 200px;
    .image {
      background-image: url(${(props) => props.image});
      background-size: cover;
    }
    .circle {
      width: 70px;
      height: 70px;
      border-radius: 100px;
    }
    .play {
      margin-left: 5px;
    }
  }
  & .text {
    height: 200px;
  }
`;

export const IndustriesSection = () => {
  return (
    <Content bgwhite pb120p="min-md" id="industries">
      <Intro
        title={t("Covering your industry")}
        sub={t(
          "Maintmark™ is a comprehensive work order and service ticket system, specifically designed to meet the needs from multiple industries such as manufacturing, facilities management and field services."
        )}
      />

      <x.div gridrow gap2="min-md" gap4="min-lg" mb4="min-md">
        <Industry
          col12="max-md"
          col4="min-md"
          image={AssetImg}
          title={t("Asset industries")}
          areas={[
            t("Energy & Utility"),
            t("Property & Facility Management"),
            t("Water & Waste Management"),
          ]}
        />
        <Industry
          col12="max-md"
          col4="min-md"
          image={ProductImg}
          title={t("Product industries")}
          areas={[
            t("Manufacturing"),
            t("Industrial Products"),
            t("Process & Food"),
          ]}
        />
        <Industry
          col12="max-md"
          col4="min-md"
          image={ServiceImg}
          title={t("Service industries")}
          areas={[
            t("Installation & Field Service"),
            t("Renewable"),
            t("Telecom"),
          ]}
        />
      </x.div>
    </Content>
  );
};
