import { createStore } from "../store";
import * as mocked from "./mocked";

export const assets = createStore({
  userId: mocked.userId,
  initial: mocked.assets,
  search: (term, assets) => {
    return assets.filter(
      (asset) => asset.name.toLowerCase().indexOf(term.toLowerCase()) >= 0
    );
  },
});
