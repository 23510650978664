import * as _ from "lodash";
import { faker } from "@faker-js/faker";
import { Asset, Filter, Group, Order, OrderUrgency, User } from "../types";
import { time } from "../time";

faker.seed(2);

export const userId = "CA373F26-4745-4703-B460-64BA62A58F93";

function randItem<T>(items: T[]) {
  return items[faker.datatype.number(items.length - 1)];
}

export const groups: Group[] = _.range(0, 3)
  .map(() => {
    return {
      __typename: "Group" as "Group",
      id: faker.datatype.uuid(),
      name: faker.company.name(),
      type: "org" as "org",
      children: [],
    };
  })
  .sort((a, b) => a.name.localeCompare(b.name));

export const users: User[] = _.range(0, 5)
  .map((index) => {
    const firstName = faker.name.firstName("male");
    const lastName = faker.name.lastName("male");
    const name = [firstName, lastName].join(" ");

    return {
      __typename: "User" as "User",
      id: index === 0 ? userId : faker.datatype.uuid(),
      firstName,
      lastName,
      name,
      short: firstName[0] + lastName[0],
      groups: [randItem(groups)],
    };
  })
  .sort((a, b) => a.name.localeCompare(b.name));

export const assets: Asset[] = _.range(0, 50)
  .map(() => ({
    id: faker.datatype.uuid(),
    name: faker.vehicle.vehicle(),
    number: faker.random.numeric(4).toString(),
  }))
  .sort((a, b) => a.name.localeCompare(b.name));

const assignTo = [...users, ...groups];

const yearStart = time.startOfYear(time.now());
const yearDays = time.differenceInCalendarDays(
  time.addYears(yearStart, 1),
  yearStart
);

export const orders: Order[] = _.range(0, 300)
  .map(() => ({
    __typename: "Order" as "Order",
    id: faker.datatype.uuid(),
    createdAt: time.now(),
    createdBy: randItem(users),
    name: _.capitalize(faker.lorem.words(faker.datatype.number(4) + 1)),
    assignedTo: randItem(assignTo),
    asset: randItem(assets),
    number: faker.random.numeric(4).toString(),
    description: faker.lorem.paragraph(),
    urgency: OrderUrgency.Normal,
    comments: _.range(0, faker.datatype.number(10)).map(() => ({
      __typename: "Comment" as "Comment",
      id: faker.datatype.uuid(),
      createdBy: randItem(users),
      createdAt:
        new Date().valueOf() - faker.datatype.number(14 * 24 * 60 * 1000),
      message: faker.lorem.sentence(10),
    })),
    reportedTime: [],
    plannedTime: 0,
    startsAt: faker.datatype.boolean()
      ? time.addDays(yearStart, faker.datatype.number(yearDays - 1))
      : 0,
  }))
  .filter((a) => a.name.indexOf("Error") === -1)
  .sort((a, b) => a.name.localeCompare(b.name));

export const filters: Filter[] = [];
