import { CSSProperties } from "react";
import { extend, ExtractPropType } from "../extend";
import generic from "../styles/generic";
import text from "../styles/text";

const styles = {
  ...generic,
  ...text,
};

const props = {};

export const img = extend<
  HTMLImageElement,
  CSSProperties | undefined,
  React.ImgHTMLAttributes<HTMLImageElement>,
  typeof styles,
  typeof props
>("img", styles, {}, props);

export type imageProps = ExtractPropType<typeof img>;
