import { Filter, AnyItem, Order, Group, User, DateTime } from "./types";
import jsonata from "jsonata";
import { data } from "./data";
import React from "react";
import _ from "lodash";
import { time } from "./time";
import { Draft, applyPatches } from "immer";
import { useContext } from "./context";
import { t } from "./locale";
import { isUserInGroup } from "./groups";

const defaultRank = time.parseISO("2000-01-01T00:00:00.000Z");
const defaultDelta = time.minutes(1);

type RankItem = Order;

function isRankItem(item: Draft<AnyItem>): item is RankItem {
  return item.__typename === "Order";
}

export function assignTo(item: Draft<AnyItem>, filter: Filter, index?: number) {
  if (isRankItem(item) && index !== undefined) {
    const value = getNewRankValue(item, filter, index);

    item.rank = {
      filterId: filter.id,
      value,
    };
  }
  applyPatches(item, filter.patches);
}

function getNewRankValue(item: Draft<AnyItem>, filter: Filter, index: number) {
  const results = getFilterResult(filter, data.orders.all());
  const fromIndex = _.findIndex(results, (x) => x.id === item.id);

  const offset = fromIndex >= 0 && fromIndex < index ? 0 : -1;

  const [before, after] = [
    results[index + offset],
    results[index + offset + 1],
  ].map((x) => (x ? getRankValue(x as Order, filter.id) : null));

  if (after === null) {
    if (before === null) {
      return defaultRank;
    } else {
      return before + defaultDelta;
    }
  } else if (before === null) {
    return after - defaultDelta;
  } else {
    return (after - before) / 2 + before;
  }
}

function getRankValue(x: AnyItem, filterId: string) {
  try {
    if ("rank" in x && x.rank) {
      if (x.rank.filterId === filterId) {
        return x.rank.value;
      }
    }
    return x.createdAt || 0;
  } catch (error) {
    debugger;
    throw error;
  }
}

export function getFilterResult(filter: Filter, orders: Order[]) {
  const exp = jsonata(filter.query);
  const result = exp.evaluate(
    { orders },
    {
      toDate(date: DateTime) {
        return time.startOfDay(date);
      },
    }
  ) as Order[];

  const items = result ? (Array.isArray(result) ? result : [result]) : [];

  return _.orderBy(items, (item) => getRankValue(item, filter.id));
}

export function useFilterItems(filter?: Filter, search?: string) {
  const orders = data.orders.useStore(
    (store) => (search ? store.search(search) : store.all()),
    [search]
  );

  return React.useMemo(() => {
    if (!filter) {
      return [];
    }

    const items = getFilterResult(filter, orders);

    if (items.length > 0) {
      if (orders.indexOf(items[0]) < 0) {
        debugger;
      }
    }

    // const result = jsonQuery(filter.query, {
    //   data: {
    //     orders,
    //   },
    //   locals: {
    //     isStartingOnDay(item, date) {
    //       return time.isSameDay(item.startsAt, Number.parseInt(date));
    //     },
    //     isAssignedTo(item, userId) {
    //       return item.assignedTo.id === userId;
    //     },
    //   },
    // });

    // const items = result.value as AnyItem[];

    return _.orderBy(items, (x) => {
      if ("rank" in x && x.rank) {
        if (x.rank.filterId === filter.id) {
          return x.rank.value;
        }
      }
      return x.createdAt;
    });
  }, [orders, filter?.query]);
}

function getOrgFilters(group: Group, user: User): Filter[] {
  if (group.type !== "org") {
    return [];
  }

  if (!isUserInGroup(user, group.id)) {
    return [];
  }

  return [
    {
      title: t("Not scheduled"),
      id: "filter:notscheduled:" + group.id,
      query: `orders[startsAt=0]`,
      patches: [
        { op: "replace", path: ["startsAt"], value: 0 },
        {
          op: "replace",
          path: ["assignee"],
          value: group,
        },
      ],
    },
    {
      title: t("Unassigned"),
      id: "filter:unassigned:" + group.id,
      query: `orders[assignedTo[id="${group.id}"]]`,
      patches: [
        {
          op: "replace",
          path: ["assignedTo"],
          value: group,
        },
      ],
    },
    {
      title: t("My work orders"),
      id: "filter:mine:" + group.id,
      query: `orders[assignedTo[id="${user.id}"]]`,
      patches: [{ op: "replace", path: ["assignedTo"], value: user }],
    },
  ];
}

type Op = "and" | "or" | "eq" | "select" | "item";

interface Expression<O extends Op> {
  op: O;
}

type Input<T> = T | Expression<any>;

interface EqExp<P> extends Expression<"eq"> {
  left: Input<P>;
  right: Input<P>;
}

interface AndExp<P> extends Expression<"and"> {
  left: Input<P>;
  right: Input<P>;
}

interface Select<P> extends Expression<"select"> {
  obj: Object;
}

type Object = Expression<"item"> | Select<any>;

interface Interface<T> {
  item(): Expression<"item">;
  and<P>(left: Input<P>, right: Input<P>): AndExp<P>;
  eq<P>(left: Input<P>, right: Input<P>): EqExp<P>;
  select<P, O>(obj: Object, select: (item: O) => P): Select<"select">;
}

export function query<T>(cb: (op: Interface<T>) => Expression<any>) {
  return cb({
    item(): Expression<"item"> {
      return {
        op: "item",
      };
    },
    and<P>(left: Input<P>, right: Input<P>): AndExp<P> {
      return {
        op: "and",
        left,
        right,
      };
    },
    eq<P>(left: Input<P>, right: Input<P>): EqExp<P> {
      return {
        op: "eq",
        left,
        right,
      };
    },
    select<P, O>(obj: Object, select: (item: O) => P): Select<P> {
      return {
        op: "select",
        obj,
      };
    },
  });
}

// query<Order>((op) =>
//   op.and(
//     op.eq<string>(
//       "asdasd",
//       op.select(op.item(), (x) => x.id)
//     ),
//     op.eq(
//       "asdasd",
//       op.select(
//         op.select(op.item(), (x) => x.assignedTo),
//         (x) => x.id
//       )
//     )
//   )
// );

export function useFilters() {
  const { user } = useContext();

  const filters = data.groups.useStore(
    (store) => {
      const allGroups = store.all();
      const orgs = allGroups.filter((g) => g.type === "org");
      return _.flatten(orgs.map((org) => getOrgFilters(org, user)));
    },
    [user]
  );

  return filters;
}
