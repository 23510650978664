import { t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";
import { Feature } from "../components/Feature";
import { Content } from "../Content";
import { Intro } from "../Intro";
import DesktopImg from "./images/desktop@3x.png";

export const DesktopSection = styled((props: x.divProps) => {
  return (
    <Content pb120p id="features" {...props}>
      <Intro title={t("How can maintmark help you?")} />
      <x.div row="min-md" centers>
        <x.div flex>
          <Feature
            textright="min-md"
            center="max-md"
            title={t("Easy to use")}
            description={t(
              "Maintmark is an easy and user-friendly way to organize your work orders. Our simple interface makes it easy to track your work orders and much more."
            )}
          />
          <Feature
            textright="min-md"
            center="max-md"
            mt4
            title={t("Excellent overview")}
            description={t(
              "Get a quick understanding of how much has been scheduled and done for the days and weeks throughout the year."
            )}
          />
        </x.div>
        <x.div className="image" row centerp centers hide="max-md">
          <x.img src={DesktopImg} width={488} height={269} />
        </x.div>
        <x.div flex>
          <Feature
            mt4="max-md"
            center="max-md"
            title={t("Flexible prioritization")}
            description={t(
              "Prioritize your work orders by dragging them. This makes it easy very easy to decide in which order things should be done."
            )}
          />
          <Feature
            center="max-md"
            mt4
            title={t("Collaborate with external organisations")}
            description={t(
              "Forward work orders to external organisations and track the progress."
            )}
          />
        </x.div>
      </x.div>
    </Content>
  );
})`
  .image {
    width: 532px;
  }
`;
