import { ui } from "@maintmark/shared";

const { styles } = ui.x;

export default {
  ...styles.text,
  bold: {
    fontWeight: 700,
  },
  extralight: {
    fontWeight: 100,
  },
  light: {
    fontWeight: 300,
  },
  regular: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
  semibold: {
    fontWeight: 600,
  },
  extrabold: {
    fontWeight: 800,
  },
  black: {
    fontWeight: 900,
  },
  mono: {
    fontFamily: "JetBrains Mono",
  },
  italic: {
    fontStyle: "italic",
  },
  underline: {
    textDecoration: "underline" as "underline",
  },
};
