import * as React from "react";
import _ from "lodash";
import { produce, Draft, produceWithPatches, Patch, applyPatches } from "immer";
import cuid from "cuid";
import { DateTime, User } from "../types";
import {
  addListener,
  createEvent,
  emit,
  EventDef,
  EventListener,
  EventResult,
} from "../events";
import { removeInPlaceIf } from "../utils";
import { time } from "../time";

export type ItemType =
  | "Order"
  | "Group"
  | "Asset"
  | "User"
  | "Filter"
  | "TimeReport"
  | "Comment"
  | "WeekKanban";

export interface Item<T extends ItemType> {
  __typename?: T;
  id: string;
  createdAt?: number;
  createdBy?: User;
}

export interface ItemDraft<T> {
  update(props: Partial<T> | ((item: Draft<NonNullable<T>>) => void)): void;
  hasChanges(): boolean;
}

export type ItemOperation = "set" | "delete";

export enum Operation {
  NoOp,
  Delete,
  Update,
  Insert,
  Undo,
}

interface ChangeBase<O extends Operation> {
  op: O;
}

interface NoChange extends ChangeBase<Operation.NoOp> {}

interface UpdateChange<T> extends ChangeBase<Operation.Update> {
  item: T;
  prev: T;
  patches: Patch[];
  revert: Patch[];
}

interface DeleteChange<T> extends ChangeBase<Operation.Delete> {
  item: T;
}

interface InsertChange<T> extends ChangeBase<Operation.Insert> {
  item: T;
}

interface UndoChange<T> extends ChangeBase<Operation.Undo> {
  change: Change<T>;
}

type Change<T> =
  | UpdateChange<T>
  | DeleteChange<T>
  | InsertChange<T>
  | UndoChange<T>
  | NoChange;

type UpdateData<T> = Partial<T> | ((item: Draft<NonNullable<T>>) => void);

type ChangeEvent<T> = {
  change: Change<T>;
  store: Items<T>;
  createdAt: DateTime;
  createdById: string;
};

export interface Items<T> {
  all(): T[];
  get(id: string): T;
  take(count: number): T[];
  has(id: string): boolean;

  upsert(items: T): Change<T>;
  insert(item: T): Change<T>;
  delete(item: { id: string } | string): Change<T>;
  update(id: string | T, data: UpdateData<T>): Change<T>;
  revert(change: Change<T>): void;

  subscribe(listener: EventListener<ChangeEvent<T>>): void;

  filter(predicate: (item: T) => boolean): T[];

  use(id: string): T;
  useStore<R>(cb: (store: Items<T>) => R, deps: React.DependencyList): R;
  useDraft(
    id: string | T
  ): [
    Readonly<T>,
    (props: Partial<T> | ((item: Draft<T>) => void)) => void,
    boolean,
    () => void
  ];
  search(term: string): T[];

  changeEvent: EventDef<ChangeEvent<T>>;
}

interface ItemsOpts<T> {
  userId: string;
  initial?: T[];
  onUpdate?: (store: Items<T>, draft: Draft<T>) => Draft<T>;
  onInsert?: (store: Items<T>, item: Draft<T>) => Draft<T>;
  onDelete?: (store: Items<T>, item: T) => void;
  search?: (term: string, items: T[]) => T[];
}

const identityFunc =
  <T>() =>
  (store: Items<T>, item: Draft<T>): Draft<T> =>
    item;

export function createStore<R extends ItemType, T extends Item<R>>(
  opts: ItemsOpts<T>
): Items<T> {
  const {
    initial = [],
    onUpdate = identityFunc<T>(),
    onInsert = identityFunc<T>(),
    onDelete = () => {},
  } = opts;
  let _items = new Map<string, T>(initial.map((item) => [item.id, item]));

  const changeEvent = createEvent<ChangeEvent<T>>("store-change");

  const store = {
    all() {
      return Array.from(_items.values());
    },
    get(id: string) {
      return _items.get(id)!;
    },
    take(count: number) {
      return Array.from(_items.values()).slice(0, count);
    },
    has(id: string) {
      return _items.has(id);
    },
    upsert(data: T): Change<T> {
      const prev = _items.get(data.id);
      if (prev) {
        return store.update(data.id, data);
      } else {
        return store.insert(data);
      }
    },
    insert(data: T): Change<T> {
      if (_items.has(data.id)) {
        throw new Error("Item already exists.");
      }

      const item = produce(data, (draft) => onInsert(store, draft));

      _items.set(item.id, item);

      const change: InsertChange<T> = {
        op: Operation.Insert,
        item,
      };

      emit(
        changeEvent({
          change,
          store,
          createdById: opts.userId,
          createdAt: time.now(),
        })
      );

      return change;
    },
    delete(item: { id: string } | string): Change<T> {
      const id = typeof item === "string" ? item : item.id;
      if (!_items.has(id)) {
        return { op: Operation.NoOp };
      }

      const toDelete = _items.get(id)!;

      onDelete(store, toDelete);

      const change: DeleteChange<T> = {
        op: Operation.Delete,
        item: toDelete,
      };

      _items.delete(id);

      emit(
        changeEvent({
          change,
          store,
          createdAt: time.now(),
          createdById: opts.userId,
        })
      );

      return change;
    },
    update(input: string | T, data: UpdateData<T>): Change<T> {
      const id = typeof input === "string" ? input : input.id;

      if (!_items.has(id)) {
        throw new Error("Item does not exist.");
      }

      const prev = _items.get(id)!;

      const [item, patches, revert] = produceWithPatches(prev, (draft) => {
        if (typeof data === "function") {
          data(draft);
        } else {
          _.assign(draft, data);
        }
        onUpdate(store, draft);
      });

      console.log(">>>>>>>>>>>>> updated", (item as any).startsAt);

      if (prev === item) {
        console.log(">>>>>>>>>>>> noop");
        return { op: Operation.NoOp };
      }

      _items.set(item.id, item);

      const change: UpdateChange<T> = {
        op: Operation.Update,
        item,
        patches,
        revert,
        prev,
      };

      emit(
        changeEvent({
          change,
          store,
          createdById: opts.userId,
          createdAt: time.now(),
        })
      );

      return change;
    },
    revert(change: Change<T>) {
      switch (change.op) {
        case Operation.Update: {
          const item = _items.get(change.item.id)!;
          _items.set(item.id, applyPatches(item, change.revert));
          break;
        }
        case Operation.Delete: {
          const { item } = change;
          _items.set(item.id, item);
          break;
        }
        case Operation.Insert: {
          _items.delete(change.item.id);
          break;
        }
        default: {
          return;
        }
      }

      emit(
        changeEvent({
          createdAt: time.now(),
          createdById: opts.userId,
          store,
          change: {
            op: Operation.Undo,
            change,
          },
        })
      );
    },
    subscribe(listener: EventListener<ChangeEvent<T>>) {
      return addListener(changeEvent, listener);
    },
    filter(predicate: (item: T) => boolean): T[] {
      const result: T[] = [];
      for (let key of Array.from(_items.keys())) {
        const item = _items.get(key)!;
        if (predicate(item)) {
          result.push(item);
        }
      }
      return result;
    },
    use(id: string) {
      return this.useStore((store) => store.get(id), [id]);
    },
    useStore<R>(cb: (store: Items<T>) => R, deps: React.DependencyList): R {
      const [value, setValue] = React.useState<R>(() => cb(store));
      const callback = React.useRef(cb);

      React.useEffect(() => {
        setValue(cb(store));
        callback.current = cb;
      }, deps);

      React.useEffect(() => {
        return store.subscribe(({ store }) => {
          setValue(callback.current!(store));
          return EventResult.Handled;
        });
      }, []);

      return value;
    },
    useDraft(
      id: string | T
    ): [
      Readonly<T>,
      (props: Partial<T> | ((item: Draft<T>) => void)) => void,
      boolean,
      () => void
    ] {
      const orgItem =
        typeof id === "string"
          ? this.useStore((store) => store.get(id), [id])
          : React.useRef({
              ...id,
              id: null,
            }).current;

      const [item, setItem] = React.useState(orgItem);
      const { current: allPatches } = React.useRef<Patch[]>([]);

      React.useEffect(() => {
        if (allPatches.length > 0) {
          setItem(applyPatches(orgItem, allPatches));
        } else {
          setItem(orgItem);
        }
      }, [orgItem]);

      const updateDraft = React.useCallback(
        (props: Partial<T> | ((item: Draft<T>) => void)) => {
          const [next, patches] = produceWithPatches(item, (draft) => {
            if (typeof props === "function") {
              props(draft);
            } else {
              _.assign(draft, props);
            }
          });

          allPatches.push(...patches);

          console.log;

          setItem(next);
        },
        [item]
      );

      const commit = React.useCallback(() => {
        if (item.id) {
          this.update(item.id, applyPatches(orgItem, allPatches));
        } else {
          const toAdd = {
            ...applyPatches(orgItem, allPatches),
            id: cuid(),
          };
          this.insert(toAdd);
          setItem(toAdd);
        }
      }, [item, orgItem]);

      return [item, updateDraft, allPatches.length > 0, commit] as [
        Readonly<T>,
        typeof updateDraft,
        boolean,
        () => void
      ];
    },
    search(term: string) {
      if (opts.search) {
        return opts.search(term, Array.from(_items.values()));
      }
      return [];
    },
    changeEvent,
  };
  return store;
}

export function useItems<T extends Item<any>, T2 extends { sourceId: string }>(
  itemsStore: Items<T>,
  transformer: (items: T[]) => T2[],
  sorter: (a: T2, b: T2) => number,
  filter: (item: T) => boolean
) {
  const [_items, setItems] = React.useState(() =>
    transformer(itemsStore.filter(filter))
  );

  React.useEffect(() => {
    const sub = itemsStore.subscribe(({ change }) => {
      setItems((_items) =>
        produce(_items, (draft) => {
          switch (change.op) {
            case Operation.Delete: {
              removeInPlaceIf(
                draft,
                (item) => item.sourceId === change.item.id
              );
              break;
            }

            case Operation.Update:
            case Operation.Insert: {
              // Remove old item
              removeInPlaceIf(
                draft,
                (item) => item.sourceId === change.item.id
              );

              // Add new items
              draft.push(...(transformer([change.item]) as Draft<T2>[]));
              break;
            }
          }
        })
      );
      return EventResult.Handled;
    });
    return sub;
  }, [_items, filter, transformer]);

  const sorted = React.useMemo(
    () => [..._items].sort(sorter),
    [_items, sorter]
  );

  return [sorted];
}
