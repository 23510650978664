import React from "react";
import { DateTime } from "../../types";
import { time } from "../../time";
import _ from "lodash";

interface Props<T> {
  startOfWeek: DateTime;
  startOfMonth: DateTime;
  startOfNextMonth: DateTime;
  data: T;
  DayComponent: React.ComponentType<
    React.PropsWithChildren<{
      startOfMonth: DateTime;
      startOfNextMonth: DateTime;
      startOfWeek: DateTime;
      data: T;
      date: DateTime;
      index: number;
    }>
  >;
}

export const Week = <T,>(props: Props<T>) => {
  const { DayComponent, startOfWeek, startOfMonth, startOfNextMonth, data } =
    props;

  return (
    <>
      {_.range(0, 7).map((index) => {
        const date = time.addDays(startOfWeek, index);
        return (
          <DayComponent
            key={date}
            index={index}
            date={date}
            startOfMonth={startOfMonth}
            startOfNextMonth={startOfNextMonth}
            startOfWeek={startOfWeek}
            data={data}
          />
        );
      })}
    </>
  );
};
