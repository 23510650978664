import { DateTime, time, ui } from "@maintmark/shared";
import React from "react";
import _ from "lodash";
import cn from "classnames";
import { x } from "../../x";
import styled from "styled-components";
import { Label } from "./Label";
import { Popup } from "../../popup";
import LeftImg from "./images/left@3x.png";
import RightImg from "./images/right@3x.png";
import CrossImg from "./images/cross@3x.png";
import DownImg from "./images/down-sm-gray300@3x.png";

interface Data {
  date: DateTime | undefined;
  setDate: (date: DateTime | undefined) => void;
}

interface DayProps {
  index: number;
  startOfMonth: DateTime;
  startOfNextMonth: DateTime;
  startOfWeek: DateTime;
  date: DateTime;
  data: Data;
}

const Day = React.memo(styled((props: DayProps & x.divProps) => {
  const { date, startOfMonth, startOfNextMonth, data } = props;

  const isActive = date >= startOfMonth && date < startOfNextMonth;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const setDate = React.useCallback(() => {
    data.setDate(date);
  }, [data, date]);

  const selected =
    !!data.date && time.isSameDay(props.date, data.date) && isActive;

  return (
    <x.div
      data-date={date}
      h35p
      w35p
      bgwhite={!selected}
      br6p
      className={cn(
        {
          day: isActive,
          selected,
        },
        props.className
      )}
      ml5p
      row
      centers
      centerp
      gray900
      gray200={!isActive}
      pointer={isActive}
      f14
      medium={isActive}
      white={selected}
      bgp800={selected}
      onClick={setDate}
    >
      {time.format(date, "d")}
    </x.div>
  );
})`
  &:hover {
    &.day:not(.selected) {
      color: ${ui.theme.colors.gray[900]} !important;
      background-color: ${ui.theme.colors.gray[100]} !important;
    }
  }
`);

interface WeekProps {
  index: number;
  startOfWeek: DateTime;
  startOfMonth: DateTime;
  startOfNextMonth: DateTime;
  data: Data;
}

const Week = React.memo((props: WeekProps) => {
  return (
    <x.div row mt3p centers>
      <x.div w12p f10 medium gray300 textright>
        {time.format(props.startOfWeek, "w")}
      </x.div>
      <ui.calendar.Week DayComponent={Day} {...props} />
    </x.div>
  );
});

const DaysOfWeek = (props: x.divProps) => {
  const { ...other } = props;

  const startOfWeek = time.startOfWeek(time.now());

  return (
    <x.div row {...other}>
      <x.div w12p />
      {_.range(0, 7).map((index) => (
        <x.div flex minw0p f10 medium gray300 uppercase row centerp ml5p>
          {time.format(time.addDays(startOfWeek, index), "eee")}
        </x.div>
      ))}
    </x.div>
  );
};

interface MonthProps {
  date: DateTime | undefined;
  data: (date: DateTime | undefined) => void;
  onClose: () => void;
}

const Month = styled((props: MonthProps & x.divProps) => {
  const { className, onClose, ...other } = props;

  const [date, setDate] = React.useState(props.date);
  const [focus, setFocus] = React.useState(props.date || time.now());

  const data = React.useMemo(
    () => ({
      date,
      setDate,
    }),
    [date]
  );

  React.useEffect(() => {
    props.data(date);
  }, [date, props.data]);

  return (
    <x.div className={className} ph20p pv20p column noselect>
      <x.div row centers h22p pr5p relative>
        <x.div
          sflex
          column
          centerp
          pointer
          onClick={time.useStep(setFocus, time.addMonths, -1)}
        >
          <x.img src={LeftImg} width={9} height={14} />
        </x.div>
        <x.div
          sflex
          column
          centerp
          ml1
          mr5p
          pointer
          onClick={time.useStep(setFocus, time.addMonths, 1)}
        >
          <x.img src={RightImg} width={9} height={14} />
        </x.div>
        <x.div fill row centerp noevents>
          <x.div f16 medium p800>
            {time.format(focus, "MMMM yyyy")}
          </x.div>
        </x.div>

        <x.div flex />
        <x.img
          src={CrossImg}
          width={14}
          height={16}
          onClick={onClose}
          pointer
        />
      </x.div>
      <DaysOfWeek sflex mt1 />
      <ui.calendar.Month
        WeekComponent={Week}
        {...other}
        date={focus}
        data={data}
      />
    </x.div>
  );
})`
  & .day {
    &:hover {
      color: ${ui.theme.colors.gray[800]};
    }
  }
`;

interface Props {
  name: string;
  date: DateTime | undefined;
  onUpdate: (date: DateTime | undefined) => void;
}

export const DateInput = (props: Props & x.divProps) => {
  const { name, onUpdate, date, ...other } = props;

  const [open, setOpen] = React.useState(false);

  const renderContent = React.useCallback(
    (close: () => void) => {
      return (
        <x.div br12p bgwhite shadowPopup>
          <Month data={onUpdate} date={date} onClose={close} />
        </x.div>
      );
    },
    [onUpdate]
  );

  return (
    <x.div column {...other} minw0p>
      <Label active={open}>{name}</Label>
      <Popup renderContent={renderContent} onToggle={setOpen}>
        <x.div
          h40p
          f14
          medium
          gray900
          bgray200
          bgray800={open}
          br3p
          ph1
          pointer
          row
          centers
          relative
        >
          {date && time.format(date, "yyyy-MM-dd")}
          <x.div right mr1>
            <x.img src={DownImg} width={10} height={7} />
          </x.div>
        </x.div>
      </Popup>
    </x.div>
  );
};
