import { t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import ModalVideo from "react-modal-video";
import React from "react";
import styled from "styled-components";
import { Content } from "../Content";
import PlayImg from "./images/play@3x.png";
import { theme } from "@maintmark/shared/src/ui";

const Button = styled((props: React.PropsWithChildren<x.aProps>) => {
  return (
    <x.a
      bgp900
      bgblack="hover"
      noselect
      br24p
      ph2
      ph20p="max-md"
      row
      centers
      white
      semibold
      f16
      f14="max-xs"
      h50p
      pointer
      nowrap
      bp900
      {...props}
    />
  );
})`
  :hover,
  :focus,
  :active {
    color: ${theme.colors.common.white};
  }
`;

export const HeadSection = styled((props: x.divProps) => {
  const [video, setVideo] = React.useState<string | null>(null);

  return (
    <Content {...props}>
      <x.div className="main" column centerp>
        <x.div className="title" column centers="max-md">
          <x.div
            p900
            black
            header
            mb2
            f64
            f50="max-md"
            f36="max-sm"
            center="max-md"
            mh4="max-md"
          >
            {t("Get your work done smarter")}
          </x.div>
          <x.div f20="max-md" f24="min-md" p900 light center="max-md">
            {t(
              "Welcome to maintmark™, an easy to use software for mobile and desktop to keep track of your work orders and ensure that they are done on time."
            )}
          </x.div>

          <x.div row mt4>
            <x.div
              bp900
              p900
              semibold
              ph2
              ph20p="max-md"
              br24p
              h50p
              centers
              centerp
              row
              noselect
              nowrap
              pointer
              bgwhite="hover"
              opacity80
              f14="max-xs"
              onClick={() => setVideo("23HabAUBmn8")}
            >
              {t("Watch how")}
              <x.img ml10p src={PlayImg} width={29} height={31} />
            </x.div>

            <x.div hide="min-md" ml1>
              <Button href="https://calendly.com/maintmark/svenska-demo-av-losning-30-min">
                {t("Book a demo")}
              </Button>
            </x.div>
          </x.div>
        </x.div>
      </x.div>

      <ModalVideo
        channel="custom"
        autoplay
        isOpen={!!video}
        start={0}
        url={"/videos/intro.mp4"}
        onClose={() => setVideo(null)}
      />
    </Content>
  );
})`
  & .main {
    height: 600px;

    @media (min-width: 576px) {
      height: 734px;
    }

    & .title {
      // @media (min-width: 576px) {
      //   height: 350px;
      //   width: 600px;
      // }
      // @media (min-width: 768px) {
      //   height: 450px;
      //   width: 600px;
      // }
      @media (min-width: 992px) {
        height: 450px;
        width: 600px;
      }
      @media (min-width: 1200px) {
        height: 450px;
        width: 600px;
      }
    }
  }
`;
