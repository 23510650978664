import { t, ui } from "@maintmark/shared";
import cuid from "cuid";
import React from "react";
import { x } from "../../x";
import styled from "styled-components";
import { Label } from "./Label";

interface Props {
  name: string;
}

const NumberInput = styled((props: x.inputProps & { max: number }) => {
  const [value, setValue] = React.useState(0);
  const [key, setKey] = React.useState(cuid());

  const selectText = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setTimeout(() => event.target.select(), 30);
    },
    []
  );

  const update = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value, 10);
      if (Number.isNaN(value)) {
        setValue(0);
      } else {
        setValue(Math.min(props.max, value));
      }

      // Force update
      setKey(cuid());
    },
    [props.max]
  );

  const keyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        (event.target as any).blur();
      }
    },
    []
  );

  return (
    <x.input
      type="number"
      key={key}
      defaultValue={value}
      flex
      textright
      minw0p
      w20p
      onBlur={update}
      onFocus={selectText}
      onKeyDown={keyDown}
      {...props}
    />
  );
})`
  &:hover {
    background-color: ${ui.theme.colors.gray[100]};
  }
`;

export const DurationInput = (props: Props & x.divProps) => {
  const { name, ...other } = props;

  // const renderMenu = React.useCallback(
  //   () => (
  //     <Menu mt5p pv1 sm align="right">
  //       <MenuButton lg>{t("15 min")}</MenuButton>
  //       <MenuButton lg>{t("30 min")}</MenuButton>
  //       <MenuButton lg>{t("45 min")}</MenuButton>
  //       <MenuButton lg>{t("1 hrs")}</MenuButton>
  //       <MenuButton lg>{t("2 hrs")}</MenuButton>
  //       <MenuButton lg>{t("3 hrs")}</MenuButton>
  //       <MenuButton lg>{t("4 hrs")}</MenuButton>
  //     </Menu>
  //   ),
  //   []
  // );

  return (
    <x.div {...other}>
      <Label>{name}</Label>
      {/* <Popup renderContent={renderMenu} align="right"> */}
      <x.div h40p f14 medium gray900 bgray200 br3p ph1 row centers flex minw0p>
        <NumberInput max={99} />
        <x.div ml2p mr3p>
          {t("d")}
        </x.div>
        <NumberInput max={23} />
        <x.div ml2p mr3p>
          {t("h")}
        </x.div>
        <NumberInput max={59} />
        <x.div ml2p>{t("m")}</x.div>
      </x.div>
      {/* </Popup> */}
    </x.div>
  );
};
