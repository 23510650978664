import { ui } from "@maintmark/shared";
import React from "react";
import styled from "styled-components";
import { x } from "../x";
import RightArrowImg from "./images/right-open-md-gray800@3x.png";

interface Props {
  sm?: boolean;
  lg?: boolean;
  align?: "left" | "right";
}

export const MenuButton = React.memo(styled(
  (props: React.PropsWithChildren<Props & x.divProps>) => {
    const { children, ...other } = props;
    return (
      <x.div
        {...other}
        f12={!props.lg}
        f14={props.lg}
        medium
        centers
        row
        pointer
        pl1={!props.lg}
        pl2={props.lg}
        pr20p
        h32p
        gray800
      >
        <x.div flex>{children}</x.div>
        <x.img src={RightArrowImg} width={6} height={10} />
      </x.div>
    );
  }
)`
  height: ${(props) => (props.lg ? 32 : 28)}px;
  &:hover {
    background-color: ${ui.theme.colors.gray[100]};
  }
`);

export const Menu = styled(
  (props: React.PropsWithChildren<Props & x.divProps>) => {
    const { children, lg, align = "left", ...other } = props;
    return (
      <x.div
        bgwhite
        br6p
        shadowPopup
        {...other}
        column
        ends={align === "right"}
      >
        {children}
      </x.div>
    );
  }
)`
  width: ${(props) => (props.lg ? 300 : props.sm ? 160 : 220)}px;
  overflow: hidden;
`;
