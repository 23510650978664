import * as _ from "lodash";
import { addListener, emit, EventResult } from "../events";
import { t } from "../locale";
import { Order } from "../types";
import { getSummaries, PatchOperations } from "../patch";
import { createStore, Operation } from "../store";
import * as mocked from "./mocked";
import { noticeEvent } from "../notifications";
import { time } from "../time";
import { users } from "./users";
import cuid from "cuid";
import { match } from "../search";

const ops: PatchOperations<Order> = {
  assignedTo: {
    replace: (assignee) => t("Assigned to {value}", { value: assignee.name }),
  },
  startsAt: {
    replace: (date) =>
      date
        ? t("Start date {value}", {
            value: time.formatRelativeDate(date),
          })
        : t("Removed start date"),
  },
};

export const orders = createStore({
  userId: mocked.userId,
  initial: mocked.orders,
  onUpdate: (store, draft) => {
    if (!draft.number) {
      // TODO: Fix me!
      draft.number = (
        (_.max(store.all().map((item) => parseInt(item.number) || 0)) || 0) + 1
      ).toString();
    }
    return draft;
  },
  search: (term, orders) => {
    return orders.filter(
      (o) =>
        match(o, term, ["description", "name", "number"]) ||
        match(o.assignedTo, term, ["name"]) ||
        (o.asset && match(o.asset, term, ["name", "number"]))
    );
  },
});

addListener(orders.changeEvent, ({ change, createdAt, createdById }) => {
  switch (change.op) {
    case Operation.Update: {
      const { patches, item } = change;
      const summaries = getSummaries(patches, ops);
      if (summaries.length > 0) {
        emit(
          noticeEvent({
            id: cuid(),
            title: item.name,
            text: summaries[0],
            createdAt: createdAt,
            createdBy: users.get(createdById).name,
            undo: () => {
              orders.revert(change);
            },
          })
        );
      }
      return EventResult.Handled;
    }
  }
  return EventResult.NotHandled;
});
