import * as React from "react";

function addListener<T>(
  node: Node | Window,
  event: string,
  listener: EventListenerOrEventListenerObject
) {
  node.addEventListener(event, listener);
  return () => node.removeEventListener(event, listener);
}

export function useResizeObserver(
  callback: () => void,
  deps: React.DependencyList
) {
  React.useEffect(() => {
    return addListener(window, "resize", callback);
  }, deps);
}

export function useHover(node: Node | null, deps: React.DependencyList) {
  const [isOver, setIsOver] = React.useState(false);

  React.useEffect(() => {
    if (!node) {
      return;
    }
    return addListener(node, "mouseover", () => setIsOver(true));
  }, [...deps, node]);

  React.useEffect(() => {
    if (!node) {
      return;
    }
    return addListener(node, "mouseleave", () => setIsOver(false));
  }, [...deps, node]);

  return isOver;
}

export function useFocus(): [boolean, () => void, () => void] {
  const [focus, setFocus] = React.useState(false);

  const setFocused = React.useCallback(() => {
    setFocus(true);
  }, []);

  const setBlurred = React.useCallback(() => {
    setFocus(false);
  }, []);

  return [focus, setFocused, setBlurred];
}
