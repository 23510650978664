import { t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import { theme } from "@maintmark/shared/src/ui";
import styled from "styled-components";
import { Content } from "./Content";

const Button = styled((props: React.PropsWithChildren<x.aProps>) => {
  return (
    <x.a
      white
      relative
      f12
      opacity60
      opacity100="hover"
      ph20p
      mb2
      pointer
      h35p
      row
      centers
      header
      {...props}
    >
      <x.div fill bgwhite opacity10 br6p />
      {props.children}
    </x.a>
  );
})`
  :hover,
  :focus,
  :active {
    color: ${theme.colors.common.white};
  }
`;

export const ContactInfo = (props: x.divProps) => {
  return (
    <x.div white header {...props}>
      <x.div opacity60 f16 row bold mb3p>
        maint
        <x.div medium>mark™</x.div>
      </x.div>
      <x.div f14 opacity30>
        <x.div>{t("Linköping Science Park")}</x.div>
        <x.div>{t("Teknikringen 10")}</x.div>
        <x.div>{t("583 30  Linköping")}</x.div>
        <x.div>{t("SWEDEN")}</x.div>
      </x.div>
    </x.div>
  );
};

export const Footer = () => {
  return (
    <Content bgp900 pv2>
      <x.div row>
        <ContactInfo mb2 />
        <x.div flex />
        <x.div sflex column ends>
          <x.div row white f14 header noselect hide="max-md">
            <x.a pointer mr2 opacity80 opacity100="hover" href="/#features">
              {t("Features")}
            </x.a>
            <x.a pointer mr2 opacity80 opacity100="hover" href="/#pricing">
              {t("Pricing")}
            </x.a>
            <x.a pointer opacity80 opacity100="hover" href="/#contact">
              {t("Contact")}
            </x.a>
          </x.div>
          <x.div flex hide="max-md" />
          <Button href="https://calendly.com/maintmark/svenska-demo-av-losning-30-min">
            <x.div fill bgwhite opacity10 br6p />
            {t("Book a demo")}
          </Button>
        </x.div>
      </x.div>
      <x.div h1p bgwhite opacity10 />
      <x.div mt2 white f12 row>
        <x.div opacity30 mr5p>
          Copyright @ {new Date().getFullYear()}
        </x.div>
        <x.div header opacity50 row bold mb3p mr5p>
          maint
          <x.div medium>mark™</x.div>
        </x.div>
        <x.div opacity30 flex>
          {t("All rights reserved.")}
        </x.div>
        <x.div header opacity30 hide="max-md">
          {t("Keep your work orders in order.")}
        </x.div>
      </x.div>
    </Content>
  );
};
