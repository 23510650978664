import { t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import { theme } from "@maintmark/shared/src/ui";
import styled from "styled-components";
import { Content } from "../Content";
import { Intro } from "../Intro";
import CheckImg from "./images/check@3x.png";

const Button = styled((props: React.PropsWithChildren<x.aProps>) => {
  return (
    <x.a
      bgp900
      bgblack="hover"
      noselect
      br24p
      ph2
      row
      centers
      centerp
      white
      semibold
      f16
      h50p
      pointer
      nowrap
      header
      {...props}
    />
  );
})`
  :hover,
  :focus,
  :active {
    color: ${theme.colors.common.white};
  }
`;

const Feature = (props: { name: string; active: boolean }) => {
  return (
    <x.div row centers mb10p opacity30={!props.active}>
      <x.img src={CheckImg} width={18} height={17} opacity0={!props.active} />
      <x.div f16 p900 medium={props.active} ml8p header>
        {props.name}
      </x.div>
    </x.div>
  );
};

interface TierProps {
  name: string;
  price: number;
  maxFeatures: number;
  popular?: boolean;
}

const features = [
  "5 users",
  "Work Order Management",
  "Service Management",
  "Task Management",
  "Scheduler",
  "Time Reporting",
  "KPI Dashboard",
  "Custom Fields",
  "IoT Connector",
  "API Access",
  "Support 24x7",
  "On-boarding & Training",
];

const Tier = styled((props: x.divProps & TierProps) => {
  const { className, name, price, maxFeatures, popular, ...other } = props;
  return (
    <x.div row col12="max-md" col4="min-md" className={className} p900>
      <x.div {...other} flex row>
        <x.div
          bgwhite
          shadowPopup
          br12p
          flex
          column
          className="content"
          p3
          relative
        >
          <x.div f24 extrabold header p900>
            {name}
          </x.div>
          <x.div row f48 semibold header ends mt1>
            {price}
            <x.div f16 bold mb8p ml10p>
              {t("SEK / month")}
            </x.div>
          </x.div>
          <x.div mt20p>
            {features.map((name, index) => (
              <Feature name={name} active={index < maxFeatures} />
            ))}
          </x.div>

          <x.div flex />

          <Button href="https://calendly.com/maintmark/svenska-demo-av-losning-30-min">
            {t("Get started")}
          </Button>

          {popular && (
            <x.div
              className="note"
              bgmarker600
              h32p
              row
              centers
              centerp
              ph20p
              white
              br24p
              f12
              bold
              header
            >
              {t("Most popular")}
            </x.div>
          )}
        </x.div>
      </x.div>
    </x.div>
  );
})`
  height: 667px;
  .content {
  }
  .note {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const PricingSection = () => {
  return (
    <Content pb120p="min-md" bgp50 id="pricing">
      <Intro
        title={t("What's the price?")}
        sub={t(
          "We understand that price matters. That's why we provide competitive prices for everyone."
        )}
      />

      <x.div gridrow>
        <Tier
          maxFeatures={5}
          price={1499}
          name={t("Basic")}
          pv1="min-md"
          pl2="min-lg"
          mb3="max-md"
          opacity90
        />
        <Tier
          maxFeatures={8}
          price={2499}
          name={t("Professional")}
          z1="min-md"
          mb3="max-md"
          popular
        />
        <Tier
          maxFeatures={100}
          price={4499}
          name={t("Enterprise")}
          pv1="min-md"
          pr2="min-lg"
          mb3="max-md"
          opacity90
        />
      </x.div>
    </Content>
  );
};
