import { createStore } from "../store";
import * as mocked from "./mocked";

export const users = createStore({
  userId: mocked.userId,
  initial: mocked.users,
  search: (term, users) => {
    return users.filter(
      (user) => user.name.toLowerCase().indexOf(term.toLowerCase()) >= 0
    );
  },
});
