import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";

interface Props {
  title: string;
  description: string;
}

export const Feature = styled((props: Props & x.divProps) => {
  const { title, description, textright, ...other } = props;
  return (
    <x.div {...other} column centers="max-md" ends={textright}>
      <x.div className="content">
        <x.div f24 bold header p800 textright={textright}>
          {title}
        </x.div>
        <x.div f16 header p900 mt10p textright={textright} opacity80>
          {description}
        </x.div>
      </x.div>
    </x.div>
  );
})`
  .content {
    max-width: 340px;
  }
`;
