import { x } from "@maintmark/shared-web/src/ui";
import React from "react";
import styled from "styled-components";

export const Content = styled((props: React.PropsWithChildren<x.divProps>) => {
  const { children, ...other } = props;
  return (
    <x.div {...other}>
      <x.div className="content" mauto>
        {children}
      </x.div>
    </x.div>
  );
})`
  .content {
    @media (max-width: 576px) {
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      margin-right: auto;
      margin-left: auto;
      box-sizing: border-box;
    }
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1200px;
    }
  }
`;
