import { Patch } from "immer";
import { t } from "./locale";

type Interpolator<P> = (value: P) => string;

export type PatchOperations<T> = {
  [P in keyof T]?: {
    replace?: Interpolator<T[P]>;
    add?: Interpolator<T[P]>;
    remove?: Interpolator<T[P]>;
  };
};

export function getSummaries<T>(patches: Patch[], ops: PatchOperations<T>) {
  const summaries: string[] = [];
  for (const patch of patches) {
    console.log(">>>>>>>>>> patch", patch);
    const path = patch.path[0] as keyof T;
    const op = ops[path];
    if (!op) {
      continue;
    }

    const template = op[patch.op];
    if (!template) {
      continue;
    }

    summaries.push(template(patch.value));
  }

  return summaries;
}
