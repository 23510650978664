import { ui } from "@maintmark/shared";
import React from "react";
import { useFocus } from "../../observable";
import { useAutosizeTextArea } from "../../textarea";
import { x } from "../../x";
import styled from "styled-components";
import { Label } from "./Label";

interface Props {
  name?: string;
  value: string;
  onUpdate: (value: string) => void;
}

export const TextArea = styled(
  (props: Props & x.divProps & Pick<x.textareaProps, "autoFocus">) => {
    const { name, autoFocus, value, onUpdate, placeholder, ...other } = props;
    const onRef = useAutosizeTextArea(100, [value]);

    const change = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onUpdate(event.target.value);
      },
      [onUpdate]
    );

    const [focus, setFocus, setBlur] = useFocus();

    return (
      <x.div column {...other} minw0p>
        {props.name && <Label active={focus}>{props.name}</Label>}
        <x.textarea
          onFocus={setFocus}
          onBlur={setBlur}
          h100p
          f14
          medium
          gray900
          bgray200
          br6p
          ph1
          pv10p
          flex
          autoFocus={autoFocus}
          ref={onRef}
          defaultValue={value}
          onChange={change}
          placeholder={placeholder}
        />
      </x.div>
    );
  }
)`
  & textarea:focus {
    border-color: ${ui.theme.colors.gray[800]} !important;
  }
`;
