import { Item, ItemType } from "./store";
import { Patch } from "immer";

export type DateTime = number;

// interface OrderAssignment {
//   type: "User" | "Order";
//   id: string;
// }

export enum OrderUrgency {
  Normal = "NORMAL",
  Urgent = "URGENT",
}

export enum PeriodUnit {
  Daily = "DAILY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR",
}

export interface Periodicity {
  startsAt: number;
  unit: PeriodUnit;
  length: number;
}

export interface Recurrence {
  index: number;
  periodicity: Periodicity;
}

export type Assignee = User | Group;

export interface Order extends Item<"Order"> {
  number: string;
  name: string;
  startsAt?: number;
  closedAt?: number;
  deletedAt?: number;
  description: string;
  assignedTo: Assignee;
  asset?: Asset;
  urgency: OrderUrgency;
  recurrence?: Recurrence;
  comments: Comment[];
  plannedTime: number;
  reportedTime: TimeReport[];
  rank?: FilterRank;
}

export interface Group extends Item<"Group"> {
  id: string;
  name: string;
  type: "org" | "dep";
  children: Group[];
  parent?: Group;
}

export interface User extends Item<"User"> {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  short: string;
  groups: Group[];
}

export interface Asset extends Item<"Asset"> {
  id: string;
  number: string;
  name: string;
}

export interface TimeReport extends Item<"TimeReport"> {
  id: string;
  duration: number;
}

export interface Comment extends Item<"Comment"> {
  id: string;
  message: string;
}

export interface Filter extends Item<"Filter"> {
  query: string;
  title?: string;
  patches: Patch[];
}

export interface FilterRank {
  filterId: string;
  value: number;
}

interface Kanban {}

export interface WeekKanban extends Kanban, Item<"WeekKanban"> {
  startOfWeek: number;
}

export type AnyItem =
  | Order
  | Comment
  | Filter
  | TimeReport
  | Asset
  | User
  | Group;

export interface Storage<T> {
  set(item: T): void;
  use(): [T, boolean];
  get(): Promise<T>;
}

export enum MenuItemType {
  Organization,
  Group,
}

export interface MenuItem {
  id: string;
  type: MenuItemType;
  title: string;
  children: MenuItem[];
  filter?: Filter;
}
